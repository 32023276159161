.page-cancel {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-cancel .content-wrap {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.page-cancel .cancel-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.page-cancel .message {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.page-cancel .description {
  font-size: 16px;
  margin-bottom: 20px;
}

.page-cancel .go-back-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}

.page-cancel .go-back-button:hover {
  background-color: #0056b3;
}