.email-signup {
  text-align: center;
  margin-top: 80px;
  padding: 40px;
  border: 0px solid #ccc;
  background-color: #3e3e3e;
  border-radius: 8px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
input {
  width: 300px;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .email-signup {
  margin-bottom: 50px;
}
  
}