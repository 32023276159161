.get-a-demo {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-bottom: 50px;
}

.get-a-demo h1 {
  text-align: center;
}

.get-a-demo p {
  text-align: center;
}

.get-a-demo form {
  display: flex;
  flex-direction: column;
}

.get-a-demo div {
  margin-bottom: 15px;
}

.get-a-demo label {
  font-weight: bold;
}

.get-a-demo input,
.get-a-demo textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.get-a-demo select {
  width: 20%;
  padding: 8px;
  margin-top: 5px;
}

.get-a-demo button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.get-a-demo button:hover {
  background-color: #0056b3;
}

.success-message {
  text-align: center;
}
