.modal {
    background-color: #2e2e2e;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  
  .modal h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .modal form {
    display: flex;
    flex-direction: column;
  }
  
  .modal label {
    margin-bottom: 10px;
    font-size: 14px;
    color: #ffffff;
  }
  
  .modal input,
  .modal textarea {
    width: 90%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .modal textarea {
    height: 80px;
    resize: vertical;
  }
  
  .modal .error {
    color: #ff4d4d;
    margin: 10px 0;
    font-size: 14px;
  }
  
  .modal button {
    background-color: #4CAF50;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .modal button:hover {
    background-color: #45a049;
  }
  
  .modal button[type="button"] {
    background-color: #f44336;
    
  }
  
  .modal button[type="button"]:hover {
    background-color: #e53935;
  }
  