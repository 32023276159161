.message-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background-color: #2e2e2e;
  border-radius: 10px;
  box-shadow: 1 4px 8px rgba(255, 255, 255, 0.1);
  z-index: 1000;
}

.message-modal-client {
  
  background-color: #2e2e2e;
  border-radius: 10px;
  box-shadow:  4px 8px rgba(0, 0, 0, 0.1);
  
}


.message-modal h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #f09a73;
  text-align: center;
}

.message-modal-client h2 {
  font-size: 1.0rem;
  margin-bottom: 15px;
  color: #f09a73;
  text-align: center;
}

.messages-container-client {
margin-top: 20px;

}

.message-modal textarea {
  width: 95%;
  height: 100px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  resize: none;
}

.message-modal textarea:focus {
  outline: none;
  border-color: #007bff;
}

.message-modal button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.message-modal button:hover {
  background-color: #0056b3;
}

.message-modal .error {
  color: red;
  margin-top: 10px;
  text-align: center;
  font-size: 0.9rem;
}

.message-modal .close-btn {
  background-color: #f44336;
  margin-top: 10px;
}

.message-modal .close-btn:hover {
  background-color: #d32f2f;
}

.message-modal-client {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-content-client {
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 80%;
  overflow-y: auto; /* Makes it scrollable */
}

.close-client {
  cursor: pointer;
  font-size: 24px;
  float: right;
}


.message-client {
  border-top: 1px solid #ddd;
  padding: 10px 0;
}

.timestamp-client {
  font-size: 0.8em;
  color: #777;
}
