/* LoginScreen.css */

.login-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.login-screen h2 {
  font-size: 1.8em;
  color: #f09a73;
  margin-bottom: 20px;
}

.login-screen .login-form {
  width: 100%;
  max-width: 400px;
  background-color: #3e3e3e;
  border: 1px solid #555;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.login-screen label {
  font-size: 0.9em;
  color: hsl(0, 0%, 100%);
}

.login-screen input {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.login-screen button {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  background-color: #3f51b5;
  color: #fff;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-screen button:hover {
  background-color: #303f9f;
}

.login-screen .error {
  color: #d9534f;
  margin-top: 10px;
  text-align: center;
}

.login-screen .myalert {
  color: #ffee00;
  font-size: 0.9em;
  margin-bottom: 10px;
  animation: blink 2s infinite; /* Apply the blinking animation */
}

@keyframes blink {
  0%, 66% {
    opacity: 0;
  }
  66%, 100% {
    opacity: 1; 
  }
}

/* Modal Overlay */
.trial-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.trial-modal-content {
  background-color: #2e2e2e;
  width: 90%;
  max-width: 400px;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* Heading */
.trial-modal-content h2 {
  font-size: 1.5rem;
  color: hsl(0, 0%, 100%);
  margin-bottom: 1rem;
}

/* Text */
.trial-modal-content p {
  color: hsl(0, 0%, 100%);
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

/* Buttons */
.trial-modal-content button {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0.3rem;
}

/* Later Button */
.trial-modal-content button:nth-child(1) {
  background-color: #cccccc;
  color: #333333;
}

.trial-modal-content button:nth-child(1):hover {
  background-color: #bbbbbb;
}

/* Upgrade Now Button */
.trial-modal-content button:nth-child(2) {
  background-color: #4caf50;
  color: white;
}

.trial-modal-content button:nth-child(2):hover {
  background-color: #45a049;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

