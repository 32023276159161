.homepage-container {
  padding: 20px;
  text-align: center;
  margin-bottom: 100px;
}

.homepage-header {
  margin-bottom: 30px;
  max-width: 60%;
  margin: auto auto;
}

.homepage-header .header-content {
  display: flex;
  align-items: center; /* vertically center image and text */
  justify-content: space-between; /* evenly space the text and image */
}

.text-content {
  flex: 1; /* allow the text to take up available space */
}

.image-content img {
  max-width: 400px; /* set a max width for the image */
  height: auto; /* ensure the image scales properly */
}
.image-dashboard img {
  max-width: 800px; /* set a max width for the image */
  height: auto; /* ensure the image scales properly */
  margin-top: 30px;
  margin-bottom: 30px;
}

.homepage-header h1 {
  font-size: 3.5rem;
  color: #f09a73;
}

.homepage-header p {
  line-height: 30px;
  font-size: 1.4rem;
  color: #ffffff;
  max-width: 80%;
  text-align: justify;
  margin: auto auto;
}

.homepage-header ul {
  line-height: 30px;
  font-size: 1.4rem;
  color: #ffffff;
  max-width: 80%;
  text-align: left;
  margin-left: 10%;
}

.homepage-header h2 {
  color: #ffffff;
  font-size: 1.8rem;
}

.pray-container {
  font-size: 20px;
  margin-top: 50px;
  color: #bdbcbc;
  background-color: #3e3e3e;
  padding: 20px;
}

.pray-container h2 {
  margin: auto auto;
  max-width: 1000px;
  text-align: center;
}

.homepage-intro,
.homepage-resources {
  margin-bottom: 40px;
}

.homepage-intro h1 {
  font-size: 3.5rem;
  color: #ffffff;
}

/* Container for the feature section */
.features-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Styling individual feature blocks */
.feature {
  display: flex;

  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Icon styling */
.icon {
  font-size: 40px;
  margin-right: 20px;

  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

/* Content styling */
.feature-content {
  display: flex;
  flex-direction: column;
}

/* Title styling */
.feature h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

/* Paragraph styling */
.feature p {
  font-size: 1.2rem;
  color: #8a8888;
  text-align: left;
}

.resources-link {
  color: #007bff;
  text-decoration: none;
}

.resources-link:hover {
  text-decoration: underline;
}

.homepage-footer {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #666;
}

.footer-link {
  color: #007bff;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.cta-container {
  text-align: center;
  margin: 50px;
}

.cta-container h1 {
  font-size: 3.5rem;

  color: white;
}

.cta-button {
  background-color: #3e3e3e;
  color: white;
  font-size: 16px;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #f09a73;
}

strong {
  color: #f09a73;
}

/* Default: Display the large image, hide the small image */
.default-image {
  display: block;
  margin: auto;
}

.small-screen-image {
  display: none;
}

.cta-buttons {
  display: flex; /* Align buttons next to each other */
  justify-content: center; /* Center the buttons horizontally */
  gap: 20px; /* Space between the buttons */
  margin-top: 20px; /* Space between the text and buttons */
}

.cta-button {
  background-color: #4CAF50; /* Green background */
  
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-grow: 1; /* Allow buttons to expand and take equal width */
}

.cta-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.cta-button:focus {
  outline: none; /* Remove focus outline */
}

.cta-button:active {
  background-color: #388e3c; /* Even darker green on click */
}

video {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.beta-access-section {
  
  border-radius: 10px;
  margin: 20px auto;
  padding: 30px;
  border-left: 5px solid #4682B4; /* Steel blue accent */
}

.beta-access-section h1 {
  color: #4682B4;
}

.beta-access-section .cta-button {
  background: linear-gradient(135deg, #4682B4, #6495ED);
  color: white;
  transform: scale(1.05);
}

/* Media query for mobile screens */
@media (max-width: 1024px) {
  .homepage-container {
    padding: 0px;
    margin-bottom: 50px;
    margin: 0px;
    width: 100%;
  }

  .homepage-header {
    flex-direction: column; /* Stack text and image vertically on small screens */
    max-width: 95%;
    margin-bottom: 50px;
  }

  .homepage-header p {
    flex-direction: column; /* Stack text and image vertically on small screens */
    max-width: 95%;
  }

  .homepage-header .header-content {
    flex-direction: column; /* Stack text and image vertically on small screens */
  }

  .image-content img {
    max-width: 100%; /* Image will fill the container on mobile */
  }
  .image-dashboard img {
    max-width: 95%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .default-image {
    display: none; /* Hide the large image */
  }

  .small-screen-image {
    display: block; /* Show the small image */
    width: 100%; /* Adjust the image size for responsiveness */
    height: auto;
    margin: auto;
  }
}