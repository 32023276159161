.settings-page {
  margin-top: 60px;
  margin-left: 20%;
  margin-bottom: 40px;
}

.setting-item {
  margin-bottom: 20px;
}

.settings-page .setting-item label {
  margin-right: 10px;
}

.settings-page .setting-item button {
  padding: 10px;
  background-color: #ff6b6b;
  color: white;
  border: none;
  cursor: pointer;
}

.settings-page .setting-item button:hover {
  background-color: #ff4f4f;
}

/* In your CSS file */
.rituals-management {
  margin-top: 20px;
  margin-right: 30%;
  padding-top: 3px;
  border: none;
  border-radius: 5px;
  background-color: #2e2e2e;
}

.ritual-item {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 1px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.rituals-management input {
  margin-left: 60px;
  margin-bottom: 20px;
}
.delete-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: darkred;
}

.add-btn {
  margin-left: 10px;
  background-color: green;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.add-btn:hover {
  background-color: darkgreen;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .settings-page {
    margin-top: 60px;
    margin-left: 1%;
    margin-bottom: 40px;
  }
  .rituals-management {
    margin-top: 20px;
    margin-right: 1%;
    padding-top: 3px;
    border: none;
    border-radius: 5px;
    background-color: #2e2e2e;
  }
}
