.success-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #2e2e2e;
  color: #fcfcfc;
}

.page-container .message {
  font-size: 24px;
  color: #f09a73;
  margin-bottom: 20px;
}

.description {
  font-size: '16px';
  margin-top: '10px';
  margin-bottom: 20px;
}

.redirect-button {
  margin-top: '20px';
  padding: '10px 20px';
  background-color: #3f51b5;
  color: 'white';
  border: 'none';
  border-radius: '5px';
  cursor: 'pointer';
  font-size: '16px';
  transition: 'background-color 0.3s';
}

.redirect-button:hover {
  background-color: #303f9f;
}

.error-message {
  color: 'red';
}

/* Main content should take up all available space */
.content-wrap {
  flex: 1;
}

/* Container for the entire page content */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
