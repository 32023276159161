.yale-brown-form {
    max-width: 1200px;
    margin: 50px auto;
    margin-top: 50px;
    padding: 20px;
    background-color: #3e3e3e;
    border: 1px solid #555;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    
  }
  
  .yale-brown-form h1 {
    font-size: 2.5rem;
    text-align: center;
    color: #f09a73;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .question-block {
    margin-bottom: 1rem; /* Optional spacing between question blocks */
  }
  
  .question-block p {
    margin: 0 0 0.5rem 0; /* Adds a bit of space below the question text */
  }
  
  .question-block label {
    display: flex;
    align-items: center;
    gap: 0; /* Ensures no gap between text and input */
    margin: 0; /* Removes any extra margin around the label */
    font-size: 14px; /* Adjust for readability */
  }
  
  .question-block input {
    margin-left: 10px; /* Completely removes default margin */
    padding: 0; /* Removes any internal padding */
    width: 16px; /* Optional: Adjust the size of the checkbox */
    height: 16px; /* Optional: Adjust the size of the checkbox */
    vertical-align: middle; /* Aligns checkbox with label text */
  }
  
  .assessment-intro {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 1rem;
    color: #f5f5f5; /* Adjust color based on your theme */
    background-color: #333; /* Optional: Add a background to highlight the text */
    padding: 1rem;
    border-radius: 5px;
    text-align: justify;
  }
  
  
  
  .score-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px auto;
    margin-top: 20px;
  
      flex-wrap: wrap; /* Allows items to wrap to the next line */
  
    
    max-width: 1200px; /* Limits the container width */
  }
  
  .score-box {
    background-color: #333;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .score-box h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .score-value {
    font-size: 2.5rem;
    font-weight: bold;
    color: #f09a73;
    margin: 10px 0;
  }
  
  .score-category {
    font-size: 1rem;
    color: white;
  }
  
  