.howto-container {
    max-width: 1200px;
    margin: 50px auto;
  }
  
  .howto-container p {
    line-height: 25px;
    font-size: 1.0rem;
    color: #ffffff;
    max-width: 100%;
    text-align: justify;
    margin: auto auto;
  }
  
  .howto-container li {
    line-height: 30px;
    font-size: 1.0rem;
    color: #ffffff;
    max-width: 100%;
    text-align: justify;
    margin: auto auto;
  }
  
  h2 {
      color: #ffffff;
    font-size: 1.8rem;
  }
  
      h1 {
        color: #f09a73;
        text-align: center;
      }
      ul {
        list-style-type: disc;
        margin-left: 20px;
      }
      .emoji {
        font-size: 1.2em;
      }
      .step {
        margin-bottom: 20px;
      }
      .divider {
        border-top: 3px solid #f09a73;
        margin: 50px 0;
      }
      .footer {
        text-align: center;
        font-size: 0.9em;
        margin-top: 30px;
        color: #666;
      }