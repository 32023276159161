.ourvision-container {
    padding: 20px;
    text-align: left;
    margin-bottom: 100px;
  }
  
  .image-content10 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .ourvision-container p {
    line-height: 30px;
    font-size: 1.4rem;
    color: #ffffff;
    max-width: 100%;
    text-align: justify;
    margin: auto auto;
  }
  
  .responsive-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* Optional for rounded corners */
    object-fit: cover; /* Ensures the image scales nicely */
  }
  
  .vision-section,
  .dream-section,
  .values-section,
  .join-section {
      padding: 20px;
      max-width: 60%;
      margin: 20px auto;
      
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .responsive {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
  }
  
  h2 {
      color: #ffffff;
    font-size: 1.8rem;
  }
  
  
  
  
  
  
  
  
  
  
  