/* General Navbar styles */
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #2e2e2e;
  font-size: 16px;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 60px; /* Smaller size for the logo */
  
}

/* Parent menu item */
.menu-item {
  position: relative;
}

.logo a {
  text-decoration: none;
  color: inherit;
}

.modal button {
  margin-left: 20px;
}

.submenu {
  display: none;
  position: absolute;
  left: 0px;
  top: 100%;
  background-color: #2e2e2e;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  z-index: 1;
  width: 320px;
}

.menu-item:hover .submenu {
  display: block;
}

.submenu li {
  padding: 8px 12px;
}

.submenu li a {
  text-decoration: none;
  color: black;
}

.submenu li a:hover {
  background-color: #f1f1f1;
}

/* Logo styles */
.logo {
  font-size: 2rem;
  font-weight: bold;
  color: #f09a73;
  margin-right: 100px;
}

/* Nav-links for desktop */
.nav-links {
  list-style-type: none;
  display: flex;
  gap: 15px;
  margin-left: auto;
}

.nav-links li a {
  text-decoration: none;
  color: #ffffff;
  padding: 10px;
}

.nav-links li a:hover {
  background-color: #111;
}

.nav-links li span:hover {
  background-color: #111;
  cursor: pointer;
}

/* Burger menu icon */
.burger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
}

.burger div {
  width: 100%;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease;
}

/* Animation for the burger icon when open */
.burger.open .line1 {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.burger.open .line2 {
  opacity: 0;
}

.burger.open .line3 {
  transform: rotate(45deg) translate(-5px, -5px);
}

/* Responsive Menu */
.navbar-text {
  display: flex;
}

.nav-links .menu-item {
  position: relative;
}

.navbar-text.open {
  display: block;
}

.nav-links {
  display: flex;
}

.nav-links.open {
  flex-direction: column;
  align-items: center;
}

.submenu-1 {
  display: none;
  position: absolute;
  left: -100px;
  top: 100%;
  background-color: #2e2e2e;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  z-index: 1;
  width: 210px;
}

.menu-item:hover .submenu-1 {
  display: block;
}

.submenu-1 li {
  padding: 8px 12px;
}

.submenu-1 li a {
  text-decoration: none;
  color: black;
}

.submenu-1 li a:hover {
  background-color: #f1f1f1;
}



/* Responsive styles */
@media (max-width: 1024px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .logo {
    margin: auto;
  }

  .submenu-1 {
  left: 0px;
}

  .navbar-text {
    display: none; /* Hide the navbar links by default */
    flex-direction: column;
    align-items: center;
    background-color: #2e2e2e;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
  }

  .nav-links {
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    width: 100%;
  }

  .burger {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    margin-top: 10px;
  }

  .burger div {
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .burger.open .line1 {
    transform: rotate(-45deg) translate(-5px, 5px);
  }

  .burger.open .line2 {
    opacity: 0;
  }

  .burger.open .line3 {
    transform: rotate(45deg) translate(-5px, -5px);
  }

  .navbar-text.open {
    display: flex; /* Show the menu when the burger is clicked */
  }
}
