

.copyrigth {
  text-align: center;
  background-color: black;
  padding: 20px 20px;
}
.urgency-notice {
  margin-left: 20%;
  margin-right: 20%;
  font-size: 12px;
  color: #f09a73;
}

.copyrigth a {
  color: white;
  text-decoration: none;
}

.copyrigth a:hover {
  text-decoration: underline;
}