/* TherapistRegistration.css */

.therapist-registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.therapist-registration h2 {
  font-size: 1.8em;
  color: #f09a73;
  margin-bottom: 20px;
}

.therapist-registration form {
  width: 100%;
  max-width: 400px;
  background-color: #3e3e3e;
  border: 1px solid #555;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  
}

.therapist-registration_1 {
 margin-top: 20px;
 margin-bottom: 50px;
 width: 100%;
 max-width: 400px;
 background-color: #3e3e3e;
 border: 1px solid #555;
 border-radius: 8px;
 box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 padding: 20px;
 }


.therapist-registration label {
  font-size: 0.9em;
  color: hsl(0, 0%, 100%);
}

.therapist-registration input {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.therapist-registration select {
  width: 95%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.therapist-registration input:focus {
  outline: none;
  border-color: #3f51b5;
  box-shadow: 0 0 5px rgba(63, 81, 181, 0.3);
}

.therapist-registration button {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  background-color: #3f51b5;
  color: #fff;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.therapist-registration button:hover {
  background-color: #303f9f;
}

.therapist-registration .error {
  color: #d9534f;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.therapist-registration .myalert {
  color: #ffee00;
  font-size: 0.9em;
  margin-bottom: 10px;
  animation: blink 2s infinite; /* Apply the blinking animation */
}

@keyframes blink {
  0%, 66% {
    opacity: 0;
  }
  66%, 100% {
    opacity: 1; 
  }
}

.r-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.r-modal {
    background-color: #3e3e3e;
  border: 1px solid #555;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.r-modal input {
  width: 90%;
}

.r-modal h3 {
  margin-top: 0;
}

.r-modal button {
  margin: 10px 5px;
  padding: 10px 15px;
  cursor: pointer;
}

