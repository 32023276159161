.pricing-container {
    padding: 2rem;
    text-align: center;
    
  }
  
  .pricing-container h1 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #f09a73;
  }
  
  .pricing-container .pricing-plans {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .pricing-plans {
    margin-bottom: 50px;
    margin-top: 100px;
  }
  
  .pricing-container .pricing-card {
    background-color: #3e3e3e;
    border: 1px solid #555;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    width: 300px;
    transition: transform 0.2s;
  }
  
  .pricing-container .pricing-card:hover {
    transform: translateY(-5px);
  }
  
  .pricing-container h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #f09a73;;
  }
  
  .pricing-container .price {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: hsl(0, 0%, 100%);
  }
  
  .pricing-container ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }
  
  .pricing-container li {
    margin: 0.5rem 0;
  }
  
  .pricing-container .cta-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pricing-container .cta-button:hover {
    background-color: #0056b3;
  }
  
  .pricing-container .audience {
    font-size: 1rem;
    margin: 0.5rem 0 1rem;
    color: #a8a8a8; /* A softer color for the audience text */
  }
  
  .pricing-container .trial {
    font-size: 1rem;
    margin: 0.5rem 0 1rem;
    color: #ff9800; /* A bright color for the trial text */
  }
  
  .pricing-container .trial-info {
    font-size: 1.1rem;
    margin: 1rem 0;
    color: #4caf50; /* A color that indicates a positive message */
    font-weight: bold;
  }