.blog-post {
  background-color: #3e3e3e;
  border: 1px solid #555;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  padding: 20px;
  margin: 20px auto;
  max-width: 1000px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.blog-post img{
  margin-left: 10% ;
  
}

.blog-post a{
  color: rgb(159, 160, 250);
  text-decoration: none;
}

.blog-post a:hover{
  color: #f09a73;
  text-decoration: none;
}

.blog-post h2 {
  color: #f09a73;
}

.blog-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #f09a73;
}

.blog-meta {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 20px;
}

.blog-date,
.blog-author {
  margin-right: 15px;
  color: #7c7c7c;
}

.blog-content {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

@media (max-width: 1024px) {
  .blog-post img{
  margin-left: 10% ;
  width: 70%;
  
}
}