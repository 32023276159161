/* TOS.css */
.tos-container {
  
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #3e3e3e;
  border: 1px solid #555;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tos-container h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.tos-container h2 {
  font-size: 20px;
  margin-top: 20px;
}

.tos-container p {
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
}

.tos-container a {
  color: #007bff;
  text-decoration: none;
}

.tos-container a:hover {
  text-decoration: underline;
}

